import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { siteColors } from "../../../common/config";
import { links } from "../../../common/routes";

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: "1rem 0",
    backgroundColor: "#fff",
    borderTop: "1px solid #ccc",
  },
  title:{
    color:'#777'
  }
}));

const FooterBottom = (props) => {
  const date = new Date().getFullYear();
  const {t,i18n} = useTranslation()
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Container>
        <div style={{display:'flex',justifyContent:'space-between'}}>

        {/* <Grid container justifyContent="center"> */}
          <div className={classes.title}>{date}.{t('all rights reserved')} @Türkmenistan DTB</div>
          <div className={classes.title}><a style={{color:siteColors.main.logo}} href={links[`privacyPolicy_${i18n.language}`]}>Privacy Policy</a></div>
        {/* </Grid> */}
        </div>
      </Container>
    </div>
  );
};

export default FooterBottom;
