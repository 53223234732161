import React from "react";
import { AccountBalance, Payment } from "@material-ui/icons";
import { NavLink } from "react-router-dom";

const A = ({
  children,
  to,
  className,
  activeclassName,
  keyName = "keyForA",
}) => {
  return (
    <a
      href={to}
      className={className}
      activeClassName={activeclassName}
      key={keyName}
    >
      {" "}
      {children}
    </a>
  );
};

const OrderCardPageContainer = React.lazy(
  () => import("./../Components/OrderCardPage/OrderCardPageContainer"),
);
const CardMoreContainer = React.lazy(
  () => import("./../Components/CardsPage/CardMore/CardMoreContainer"),
);
const CreditPageContainer = React.lazy(
  () => import("./../Components/CreditPage/CreditPageContainer"),
);
const CardsPageContainer = React.lazy(
  () => import("./../Components/CardsPage/CardsPageContainer"),
);
const ApplicantPageContainer = React.lazy(
  () => import("./../Components/ApplicantPage/ApplicantPageContainer"),
);
const ModifyApplicantContainer = React.lazy(
  () =>
    import(
      "./../Components/ApplicantPage/ModifyApplicant/ModifyApplicantContainer"
    ),
);
const PaymentResultContainer = React.lazy(
  () =>
    import(
      "./../Components/ApplicantPage/PaymentResult/PaymentResultContainer"
    ),
);
const BranchInfoContainer = React.lazy(
  () => import("./../Components/common/BranchInfo/BranchInfoContainer"),
);

export const RoutesNames = {
  ORDERCARD: "/cards/orderCard",
  CARDMORE: "/cards/cardMore",
  CARDS: "/cards",
  CREDITS: "/credits",
  SERVICES: "/services",
  APPLICANT: "/applicant",
  PAYMENTRESULT: "/paymentresult",
  MODIFYAPPLICANT: "/applicant/modify",
  BRANCH: "/applicant/branchInfo",
};

export const links = {
  loan: "https://loan.tnbk.tm",
  main: "https://www.tnbk.tm",
  tarif: "https://www.tnbk.tm/tm/rko.html",
  privacyPolicy_tm: "https://www.tnbk.tm/Privacy_Policy_tm.pdf",
  privacyPolicy_ru: "https://www.tnbk.tm/Privacy_Policy_ru.pdf",
};
export const navRoutes = [
  {
    path: RoutesNames.CARDS,
    icon: Payment,
    title: "card-application",
    component: NavLink,
    action: function () { },
  },
  {
    path: links.loan,
    icon: AccountBalance,
    title: "credit-application",
    component: A,
    action: function () { },
  },
];

export const publicRoutes = [
  {
    path: RoutesNames.ORDERCARD,
    exact: true,
    component: OrderCardPageContainer,
  },
  { path: RoutesNames.CARDMORE, exact: true, component: CardMoreContainer },
  { path: RoutesNames.CARDS, exact: true, component: CardsPageContainer },
  { path: RoutesNames.CREDITS, exact: true, component: CreditPageContainer },
  {
    path: RoutesNames.APPLICANT,
    exact: true,
    component: ApplicantPageContainer,
  },
  {
    path: RoutesNames.MODIFYAPPLICANT,
    exact: true,
    component: ModifyApplicantContainer,
  },
  {
    path: RoutesNames.PAYMENTRESULT,
    exact: false,
    component: PaymentResultContainer,
  },
  { path: RoutesNames.BRANCH, exact: true, component: BranchInfoContainer },
];
