import { CardAPI } from "../api/api"
import { returnTranslation } from "../common/tools"
import {  setIsFetching, setOpenCheckOrder } from "./data-reducer"
import React from 'react'


const NameSearch =  React.lazy(()=>import('../Components/forms/NameSearch'))
const SurnameSearch = React.lazy(()=>import('../Components/forms/SurnameSearch'))
const ThirdNameSearch = React.lazy(()=>import('../Components/forms/ThirdnameSearch'))
const GivenDate = React.lazy(()=>import('../Components/forms/Passport/PassportGivenDate'))
const GivenPlace = React.lazy(()=>import('../Components/forms/Passport/PassportGivenPlace')) 
const BirthDate  = React.lazy(()=>import('../Components/forms/Birthday'))
const Email = React.lazy(()=>import('../Components/forms/Email'))
const Branch = React.lazy(()=>import('../Components/forms/Branch'))
const LivingPlace = React.lazy(()=>import('../Components/forms/Address'))
const CardCreateType = React.lazy(()=>import('../Components/OrderCardPage/OrderForm/CardCreateType/CardCreateType'))
const Passport  = React.lazy(()=>import('../Components/forms/Passport/Passport')) 

const SET_ACTIVE_STATUS_APR = 'SET_ACTIVE_STATUS_APR'
const SET_CHECK_DATA_ARP ='SET_CHECK_DATA_ARP'
const SET_CHECK_DATA_MESSAGE_ARP = 'SET_CHECK_DATA_MESSAGE_ARP'
const SET_HAS_APPLICANT_ARP ='SET_HAS_APPLICANT_ARP'
const SET_IS_SUCCESS_REJECTED = 'SET_IS_SUCCESS_REJECTED'
const SET_REJECT_APPLICANT_MESSAGE = 'SET_REJECT_APPLICANT_MESSAGE'
const SET_IS_MODIFIED = 'SET_IS_MODIFIED'
const SET_MODIFY_APPLICANT_MESSAGE = 'SET_MODIFY_APPLICANT_MESSAGE'
const SET_REDIRECTED_FOR_PAYING = 'SET_REDIRECTED_FOR_PAYING' 



const initialState = {
    activeStatus:1,
    hasApplicant:false,
    checkDataMessage:'',
    rejectApplicantMessage:'',
    isSuccessRejected:null,
    isModified:null,
    modifyApplicantMessage:'',
    redirectedForPaying:false,
    statusData:[
        {id:0,status:1,title:{ru:'Создана заявка',tm:'Ýüzlenme döredildi',en:'Создана заявка'},changable:false,cancelable:false,color:'#999999',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'operation',value:'operation', valueInput:{tm:'Ýüzlenme döredildi №',en:'Создана заявка №',ru:'Создана заявка №'}},
            {id:3,title:'date',value:'date'},
            {id:4,title:'branch', value:'branch'},
        ],
            description_ru:'Это означает, что заявка находится на стороне банка и ожидает реакции со стороны сотрудника банка',
            description_tm:'Это означает, что заявка находится на стороне банка и ожидает реакции со стороны сотрудника банка',
            description_en:'Это означает, что заявка находится на стороне банка и ожидает реакции со стороны сотрудника банка'
        },
        {id:1,status:2,title:{ru:'Принята к рассмотрению',tm:'Ýüzlenme seredilmäne kabul edildi',en:'Принята к рассмотрению'},color:'#F4CA16',changable:false,cancelable:false,params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'performer', value:'performer'},
            {id:3,title:'operation', value:'operation',valueInput:{tm:'Ýüzlenme seredilmäne kabul edildi',en:'Заявка принята к рассмотрению',ru:'Заявка принята к рассмотрению'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},

        ],
            description_ru:'Это означает, что на стороне банка инициированы процедуры предварительного анализа заказчика по указанным в заявке персональным данным',
            description_tm:'Это означает, что на стороне банка инициированы процедуры предварительного анализа заказчика по указанным в заявке персональным данным',
            description_en:'Это означает, что на стороне банка инициированы процедуры предварительного анализа заказчика по указанным в заявке персональным данным'
        },
        {id:2,status:3,title:{ru:'Заявка на доработке',tm:'Sargyt düzedilýär',en:'Заявка на доработке'},color:'#ff0000',changable:true,cancelable:false,params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'performer', value:'performer'},
            {id:3,title:'operation',value:'operation', valueInput:{tm:'Sargyt düzedilýär',en:'Возвращена на доработку',ru:'Возвращена на доработку'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},
            {id:6,title:'comment', value:'text'},
        ],
            description_ru:'этот статус принимает некорректно заполненная (по различным причинам) заявка и заявка переходит на сторону заказчика для внесения изменений',
            description_tm:'этот статус принимает некорректно заполненная (по различным причинам) заявка и заявка переходит на сторону заказчика для внесения изменений',
            description_en:'этот статус принимает некорректно заполненная (по различным причинам) заявка и заявка переходит на сторону заказчика для внесения изменений'
        },
        {id:3,status:4,title:{ru:'Заявка отклонена',tm:'Sargyt ret edildi',en:'Заявка отклонена'},color:'#8E0000',changable:false,cancelable:false,params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'performer', value:'performer'},
            {id:3,title:'operation', value:'operation',valueInput:{tm:'Sargyt ret edildi',en:'Заявка отклонена',ru:'Заявка отклонена'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},
            {id:6,title:'comment', value:'text'},
        ],
            description_ru:'заявка принимает этот статус, когда некорректно внесенные в заявку данные исправить невозможно. Например, заказчик не выходит на контакт с исполнителем банка или сам предлагает закрыть заявку, так как он признался, что заполнил заявку, будучи в рассеянном состоянии.  Дальнейшие операции с заявкой становятся невозможными.',
            description_tm:'заявка принимает этот статус, когда некорректно внесенные в заявку данные исправить невозможно. Например, заказчик не выходит на контакт с исполнителем банка или сам предлагает закрыть заявку, так как он признался, что заполнил заявку, будучи в рассеянном состоянии.  Дальнейшие операции с заявкой становятся невозможными.',
            description_en:'заявка принимает этот статус, когда некорректно внесенные в заявку данные исправить невозможно. Например, заказчик не выходит на контакт с исполнителем банка или сам предлагает закрыть заявку, так как он признался, что заполнил заявку, будучи в рассеянном состоянии.  Дальнейшие операции с заявкой становятся невозможными.'
        },
        {id:4,status:5,title:{ru:'Завершена по таймауту',tm:'Ýüzlenme wagty gutardy',en:'Завершена по таймауту'},color:'#000000',changable:false,cancelable:false,params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'performer', value:'performer'},
            {id:3,title:'operation',value:'operation', valueInput:{tm:'Ýüzlenme wagty gutardy',en:'Завершена по таймауту',ru:'Завершена по таймауту'}},
            {id:5,title:'date',value:'date'},
            {id:6,title:'branch', value:'branch'},

        ],
            description_ru:'если в течение срока заявка, переданная на доработку, не была исправлена, то с этого момента заявка считается недействительной и дальнейшие операции с заявкой становятся невозможными',
            description_tm:'если в течение срока заявка, переданная на доработку, не была исправлена, то с этого момента заявка считается недействительной и дальнейшие операции с заявкой становятся невозможными',
            description_en:'если в течение срока заявка, переданная на доработку, не была исправлена, то с этого момента заявка считается недействительной и дальнейшие операции с заявкой становятся невозможными'
        },
        {id:5,status:6,title:{ru:'Возврат после доработки',tm:'Sargyt düzedildi',en:'Возврат после доработки'},changable:false,cancelable:false,color:'#20B2AA',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'operation',value:'operation', valueInput:{tm:'Sargyt düzedildi',en:'Возврат после доработки',ru:'Возврат после доработки'}},
            {id:3,title:'date',value:'date'},
            {id:4,title:'branch', value:'branch'},

        ],
            description_ru:'если в течении срока действия тикета заявка была исправлена заказчиком, то она приобретает этот статус и становится доступна сотруднику для дальнейшего рассмотрения',
            description_tm:'если в течении срока действия тикета заявка была исправлена заказчиком, то она приобретает этот статус и становится доступна сотруднику для дальнейшего рассмотрения',
            description_en:'если в течении срока действия тикета заявка была исправлена заказчиком, то она приобретает этот статус и становится доступна сотруднику для дальнейшего рассмотрения'
        },
        {id:6,status:7,title:{ru:'Заявка одобрена',tm:'Ýüzlenme kabul edildi',en:'Заявка одобрена'},changable:false,cancelable:false,color:'#50C878',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'performer', value:'performer'},
            {id:3,title:'operation',value:'operation', valueInput:{tm:'Ýüzlenme kabul edildi',en:'Заявка одобрена',ru:'Заявка одобрена'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},

        ],
            description_ru:'заявка приобретает этот статус после успешной проверки специалистом банка всех реквизитов заявки, и личность самого заказчика подтверждена телефонным звонком. Заявка переходит на сторону заказчика для проведения оплаты. Заказчику направляется уведомление с указанием времени, в течение которого он должен произвести оплату банковских услуг',
            description_tm:'заявка приобретает этот статус после успешной проверки специалистом банка всех реквизитов заявки, и личность самого заказчика подтверждена телефонным звонком. Заявка переходит на сторону заказчика для проведения оплаты. Заказчику направляется уведомление с указанием времени, в течение которого он должен произвести оплату банковских услуг',
            description_en:'заявка приобретает этот статус после успешной проверки специалистом банка всех реквизитов заявки, и личность самого заказчика подтверждена телефонным звонком. Заявка переходит на сторону заказчика для проведения оплаты. Заказчику направляется уведомление с указанием времени, в течение которого он должен произвести оплату банковских услуг'
        },
        {id:7,status:8,title:{ru:'Завершена по таймауту',tm:'Ýüzlenme wagty gutardy',en:'Завершена по таймауту'},changable:false,cancelable:false,color:'#000000',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'performer', value:'performer'},
            {id:3,title:'operation',value:'operation', valueInput:{tm:'Ýüzlenme wagty gutardy',en:'Завершена по таймауту',ru:'Завершена по таймауту'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},

        ],
            description_ru:'если в течение определенного времени услуги банка не будут оплачены, предполагается, что заказчик передумал заказывать изготовление карты и заявка переводится в этот статус. С этого момента заявка считается недействительной и дальнейшие операции с заявкой становятся невозможными',
            description_tm:'если в течение определенного времени услуги банка не будут оплачены, предполагается, что заказчик передумал заказывать изготовление карты и заявка переводится в этот статус. С этого момента заявка считается недействительной и дальнейшие операции с заявкой становятся невозможными',
            description_en:'если в течение определенного времени услуги банка не будут оплачены, предполагается, что заказчик передумал заказывать изготовление карты и заявка переводится в этот статус. С этого момента заявка считается недействительной и дальнейшие операции с заявкой становятся невозможными'
        },
        {id:8,status:9,title:{ru:'Отказ от заявки',tm:'Sargyt ulanyjy tarapyndan ret edildi',en:'Отказ от заявки'},changable:false,cancelable:false,color:'#536872',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'performer', value:'performer'},
            {id:3,title:'operation',value:'operation', valueInput:{tm:'Sargyt ulanyjy tarapyndan ret edildi',en:'Отказ от заявки',ru:'Отказ от заявки'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},

        ],
            description_ru:'заявка приобретает этот статус после того как заказчик среагировал в течении времени, отведенного на таймаут и, ознакомился с условиями оплаты, определил для себя эти условия неприемлемыми и самостоятельно закрыл заявку. Дальнейшие операции с заявкой становятся невозможными.',
            description_tm:'заявка приобретает этот статус после того как заказчик среагировал в течении времени, отведенного на таймаут и, ознакомился с условиями оплаты, определил для себя эти условия неприемлемыми и самостоятельно закрыл заявку. Дальнейшие операции с заявкой становятся невозможными.',
            description_en:'заявка приобретает этот статус после того как заказчик среагировал в течении времени, отведенного на таймаут и, ознакомился с условиями оплаты, определил для себя эти условия неприемлемыми и самостоятельно закрыл заявку. Дальнейшие операции с заявкой становятся невозможными.'
        },
        {id:9,status:10,title:{ru:'Заявка оплачена',tm:'Sargyt tölendi',en:'Заявка оплачена'},changable:false,cancelable:false,color:'#6FFF27',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'operation',value:'operation', valueInput:{tm:'Sargyt tölendi',en:'Заявка оплачена',ru:'Заявка оплачена'}},
            {id:3,title:'date',value:'date'},
            {id:4,title:'branch', value:'branch'},

        ],
            description_ru:'заявка принимает этот статус после успешной оплаты заказчиком банковских услуг по изготовлению карты.',
            description_tm:'заявка принимает этот статус после успешной оплаты заказчиком банковских услуг по изготовлению карты.',
            description_en:'заявка принимает этот статус после успешной оплаты заказчиком банковских услуг по изготовлению карты.'
        },
        {id:10,status:11,title:{ru:'Одобрено изготовление',tm:'Ýüzlenme tassyklanyldy',en:'Одобрено изготовление'},changable:false,cancelable:false,color:'#6FFF27',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'performer', value:'performer'},
            {id:3,title:'operation',value:'operation', valueInput:{tm:'Одобрено изготовление',en:'Одобрено изготовление',ru:'Одобрено изготовление'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},

        ],
            description_ru:'выполнение процедур по непосредственному исполнению заявки.',
            description_tm:'выполнение процедур по непосредственному исполнению заявки.',
            description_en:'выполнение процедур по непосредственному исполнению заявки.'
        },
        {id:11,status:12,title:{ru:'Заявка на расследовании',tm:'Ýüzlenme duruzyldy',en:'Заявка на расследовании'},changable:false,cancelable:false,color:'#F07427',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'performer', value:'performer'},
            {id:3,title:'operation',value:'operation', valueInput:{tm:'Заявка на расследовании',en:'Заявка на расследовании',ru:'Заявка на расследовании'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},

        ],
            description_ru:'если  оплата не подтверждена, то сотрудник инициирует расследование инцидента до окончательного выяснения обстоятельств.',
            description_tm:'если  оплата не подтверждена, то сотрудник инициирует расследование инцидента до окончательного выяснения обстоятельств.',
            description_en:'если  оплата не подтверждена, то сотрудник инициирует расследование инцидента до окончательного выяснения обстоятельств.'
        },
        {id:12,status:13,title:{ru:'Карта выпущена',tm:'Kart taýýarlanyldy',en:'Карта выпущена'},changable:false,cancelable:false,color:'#50C878',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'performer', value:'performer'},
            {id:3,title:'operation',value:'operation', valueInput:{tm:'Карта выпущена',en:'Карта выпущена',ru:'Карта выпущена'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},

        ],
            description_ru:'после того как сотрудник банка получит изготовленную по заявке карту, он переводит заявку в этот статус.',
            description_tm:'после того как сотрудник банка получит изготовленную по заявке карту, он переводит заявку в этот статус.',
            description_en:'после того как сотрудник банка получит изготовленную по заявке карту, он переводит заявку в этот статус.'
        },
        {id:13,status:14,title:{ru:'Карта доставляется',tm:'Kart eltip berilýär',en:'Карта доставляется'},changable:false,cancelable:false,color:'#318CE7',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'operation',value:'operation', valueInput:{tm:'Выбрана доставка карты',en:'Выбрана доставка карты',ru:'Выбрана доставка карты'}},
            {id:3,title:'date',value:'date'},
            {id:4,title:'branch', value:'branch'},

        ],
            description_ru:'этот статус приобретает карта после того как заказчик на сайте выбрал режим доставки.',
            description_tm:'этот статус приобретает карта после того как заказчик на сайте выбрал режим доставки.',
            description_en:'этот статус приобретает карта после того как заказчик на сайте выбрал режим доставки.'
        },
        {id:14,status:15,title:{ru:'Извещение о доставке принято',tm:'Извещение о доставке принято',en:'Извещение о доставке принято'},changable:false,cancelable:false,color:'#318CE7',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'deliveryman', value:'deliveryman'},
            {id:3,title:'operation',value:'operation', valueInput:{tm:'Заявка на доставку принята',en:'Заявка на доставку принята',ru:'Заявка на доставку принята'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},

        ],
            description_ru:'сотрудник службы доставки принимает ее к исполнению..',
            description_tm:'сотрудник службы доставки принимает ее к исполнению..',
            description_en:'сотрудник службы доставки принимает ее к исполнению..'
        },
        {id:15,status:16,title:{ru:'Карта передана доставщику',tm:'Kart çapara berildi',en:'Карта передана доставщику'},changable:false,cancelable:false,color:'#F4CA16',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'performer', value:'performer'},
            {id:3,title:'operation',value:'operation', valueInput:{tm:'Карта передана доставщику',en:'Карта передана доставщику',ru:'Карта передана доставщику'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},

        ],
            description_ru:'сотрудник банка передает карту доставщику',
            description_tm:'сотрудник банка передает карту доставщику',
            description_en:'сотрудник банка передает карту доставщику'
        },
        {id:16,status:17,title:{ru:'Карта доставлена',tm:'Kart eltip berildi',en:'Карта доставлена'},changable:false,cancelable:false,color:'#312AE7',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'deliveryman', value:'deliveryman'},
            {id:3,title:'operation',value:'operation', valueInput:{tm:'Карта доставлена',en:'Карта доставлена',ru:'Карта доставлена'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},

        ],
            description_ru:'сотрудник службы доставки после передачи карты заказчику ',
            description_tm:'сотрудник службы доставки после передачи карты заказчику ',
            description_en:'сотрудник службы доставки после передачи карты заказчику '
        },
        {id:17,status:18,title:{ru:'Карта получена',tm:'Карта получена',en:'Карта получена'},changable:false,cancelable:false,color:'#006262',params:[
            {id:0,title:'card-type',value:'card_type_id'},
            {id:1,title:'card-create-type', value:'type'},
            {id:2,title:'performer', value:'performer'},
            {id:3,title:'operation',value:'operation', valueInput:{tm:'Карта получена',en:'Карта получена',ru:'Карта получена'}},
            {id:4,title:'date',value:'date'},
            {id:5,title:'branch', value:'branch'},

        ],
            description_ru:'Карта получена ',
            description_tm:'Карта получена ',
            description_en:'Карта получена '
        },

    ],
    userParams:[
        {id:0, title: 'name',value:'name'},
        {id:1, title: 'surname',value:'surname'},
        {id:2, title: 'middle-name',value:'patronymic'},
        {id:3, title: 'phone',value:'phone'},
        {id:4, title: 'passport',value:'passport_serie,passport_number'},
        {id:5, title: 'givenDate',value:'given_date'},
        {id:6, title: 'givenPlace',value:'given_place'},
        {id:7, title: 'birthDate',value:'birth_date'},
        {id:8, title: 'livingPlace',value:'living_place'},       
    ],
    modifyParams:[
        {id:0, title: 'select-card-app',value:'type',component:CardCreateType,render:true},
        {id:1, title: 'name',value:'name',component:NameSearch,render:true},
        {id:2, title: 'surname',value:'surname',component:SurnameSearch,render:true},
        {id:3, title: 'middle-name',value:'patronymic',component:ThirdNameSearch,render:true},
        {id:12, title: 'passport',value:'passport_serie',component:Passport,render:true},
        // {id:13, title: 'passport',value:'passport_number',component:Passport,render:true},
        {id:4, title: 'givenDate',value:'given_date',component:GivenDate,render:true},
        {id:5, title: 'givenPlace',value:'given_place',component:GivenPlace,render:true},
        {id:6, title: 'birthDate',value:'birth_date',component:BirthDate,render:true},
        {id:7, title: 'livingPlace',value:'living_place',component:LivingPlace,render:true},
        // {id:9, title: 'Пин конверт',value:'pin_convert',render:true},
        {id:8, title: 'branch',value:'branch_id',component:Branch,render:true},
        {id:11, title: 'email',value:'email',component:Email,render:true},
        

    ],  
    paymentParams:[
        {id:0,title:'openAccount',value:'accountCreate'},
        {id:1,title:'cardIssuance',value:'cardPrice'},
        {id:2,title:'pinConvertPrint',value:'pinConvert'},
        {id:3,title:'smsMessages',value:'sms'},
        {id:4,title:'total',value:'all'}, 

    ],
    checkData:{
        applicant:{
            id: 1,
            offer_id: "",
            status: 1,
            type: 1,
            card_type_id: 1,
            user_id: 1,
            amount: 1,
            phone: "",
            name: "",
            surname: "",
            patronymic: "",
            email: "",
            passport_serie: "",
            passport_number: "",
            given_date: "",
            birth_date: "",
            given_place: "",
            living_place: "",
            branch_id: 1,
            phone_verified: 1,
            delivery: 0,
            payed: 0,
            created_at: "",
            images: null,
            user_response: null,
            delivery_man_id: null,
            payment_amount_desc: null,
            pin_convert: 0,
            branch:null

        }
    },

}

export const setActiveStatus = (activeStatus)=>({type:SET_ACTIVE_STATUS_APR,activeStatus})
export const setCheckData = (checkData)=>({type:SET_CHECK_DATA_ARP,checkData})
export const setCheckDataMessage = (checkDataMessage)=>({type:SET_CHECK_DATA_MESSAGE_ARP,checkDataMessage})
export const setHasApplicant = (hasApplicant) =>({type:SET_HAS_APPLICANT_ARP,hasApplicant})

export const setReject = (isSuccessRejected) =>({type:SET_IS_SUCCESS_REJECTED, isSuccessRejected})
export const setRejectApplicantMessage = (rejectApplicantMessage) =>({type:SET_REJECT_APPLICANT_MESSAGE,rejectApplicantMessage})

export const setModify = (isModified) =>({type:SET_IS_MODIFIED, isModified})
export const setModifyApplicantMessage = (modifyApplicantMessage) =>({type:SET_MODIFY_APPLICANT_MESSAGE,modifyApplicantMessage})

export const setRedirectedForPaying = (redirectedForPaying) =>({type:SET_REDIRECTED_FOR_PAYING, redirectedForPaying})
export default function applicantReducer (state=initialState, action){
    switch(action.type){
        case SET_ACTIVE_STATUS_APR:
            return{
                ...state,
                activeStatus:action.activeStatus
            }
        case SET_CHECK_DATA_ARP:
            return{
                ...state,
                checkData:action.checkData
            }
        case SET_CHECK_DATA_MESSAGE_ARP:
            return{
                ...state,
                checkDataMessage:action.checkDataMessage
            }
        case SET_HAS_APPLICANT_ARP:
            return{
                ...state,
                hasApplicant:action.hasApplicant
            }
        case SET_IS_SUCCESS_REJECTED:
            return{
                ...state,
                isSuccessRejected: action.isSuccessRejected
            }
        case SET_REJECT_APPLICANT_MESSAGE:
            return{
                ...state,
                rejectApplicantMessage:action.rejectApplicantMessage
            }
        case SET_REDIRECTED_FOR_PAYING:
            return{
                ...state,
                redirectedForPaying:action.redirectedForPaying
            }
        case SET_IS_MODIFIED:
            return{
                ...state,
                isModified:action.isModified
            }
        case SET_MODIFY_APPLICANT_MESSAGE:
            return{
                ...state,
                modifyApplicantMessage:action.modifyApplicantMessage
            }
        default: return state
    }
}


export const clearCardCheckData = ()=>{
    return dispatch =>{
        dispatch(setHasApplicant(false))
        dispatch(setCheckData({}))
        dispatch(setActiveStatus(1))
        dispatch(setReject(null))
        dispatch(setRejectApplicantMessage(''))
        dispatch(setModify(null))
        dispatch(setModifyApplicantMessage(''))
        dispatch(setCheckDataMessage(''))

    }
}


export const setCheckDataThunk = (data)=>{
    return async dispatch =>{
        dispatch(setIsFetching(true))
        dispatch(setCheckDataMessage(''))
        try{
            const response = await CardAPI.postCheckApplicant(data)
            if(response.status ===200&&response.data.status ===0&&response.data.msg!=='Not found this applicant'){
                dispatch(setOpenCheckOrder(false))
                dispatch(setCheckData(response.data.data))
                dispatch(setActiveStatus(response.data.data.applicant.status))
                dispatch(setHasApplicant(true))
            }
            else{
                dispatch(setHasApplicant(false))
                dispatch(setCheckDataMessage(returnTranslation('invalid-credentials')))
            }
            dispatch(setIsFetching(false))

        }
        catch(e){
            if (e.response&&(e.response.status ===400||e.response.status ===500)){
                dispatch(setCheckDataMessage(returnTranslation('invalid-credentials')))
            }

            else dispatch(setCheckDataMessage(returnTranslation('some-errors')))
            dispatch(setIsFetching(false))
            
        }
    }
}


export const rejectApplicantThunk = (data)=>{
    return async dispatch =>{
        dispatch(setIsFetching(true))
        dispatch(setRejectApplicantMessage(''))
        dispatch(setReject(null))
        try{
            const response = await CardAPI.postRejectApplicant(data)
            if(response.status ===200&&response.data.status ===0){
                dispatch(setRejectApplicantMessage('success'))
                dispatch(setReject(true))

            }
            else if (response.status ===200){
                dispatch(setRejectApplicantMessage(returnTranslation('invalid-credentials')))
                dispatch(setReject(false))

            }
           
            dispatch(setIsFetching(false))

        }
        catch(e){
            if (e.response&&(e.response.status ===400||e.response.status ===500)){
                dispatch(setRejectApplicantMessage(returnTranslation('invalid-credentials')))
            }

            else dispatch(setRejectApplicantMessage(returnTranslation('some-errors')))
            dispatch(setReject(false))
            dispatch(setIsFetching(false))
        }
    }
}


export const modifyApplicantThunk = (data)=>{
    return async dispatch =>{
        dispatch(setIsFetching(true))
        dispatch(setModifyApplicantMessage(''))
        dispatch(setModify(null))
        try{
            const response = await CardAPI.postModifyApplicant(data)
            if(response.status ===200&&response.data.status ===0){
                dispatch(setModifyApplicantMessage('success'))
                dispatch(setModify(true))
                dispatch(clearCardCheckData())
                // dispatch(setCheckDataThunk({
                //    offer_id:response.data.data.offer_id,
                //    tiket:response.data.data.actions[response.data.data.actions.length-1].tiket_verify
                // }))

            }
            else if (response.status ===200){
                dispatch(setModifyApplicantMessage(returnTranslation('invalid-credentials')))
                dispatch(setModify(false))

            }
           
            dispatch(setIsFetching(false))

        }
        catch(e){
            if (e.response&&(e.response.status ===400||e.response.status ===500)){
                dispatch(setModifyApplicantMessage(returnTranslation('invalid-credentials')))
                dispatch(setModify(false))
                
            }
            else  dispatch(setModifyApplicantMessage(returnTranslation('some-errors')))
            dispatch(setModify(false))
            dispatch(setIsFetching(false))
        }
    }
}