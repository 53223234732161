import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from 'redux-thunk'
import applicantReducer from "./applicant-reducer";
import cardReducer from "./card-reducer";
import creditReducer from "./credit-reducer";
import dataReducer from "./data-reducer";


const reducers = combineReducers({
    cardPage: cardReducer,
    creditPage:creditReducer,
    applicantPage:applicantReducer,
    data: dataReducer,

})


const store = createStore(reducers, applyMiddleware(thunk))
window.treys = store

export default store