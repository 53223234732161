import { CardAPI } from "../api/api";
import { config } from "../common/config";
import {
  // determineRegByCode,
  returnTranslation,
  setActiveBranch_local,
  setActiveOrderCard_local,
} from "../common/tools";
import { setIsFetching } from "./data-reducer";
// import { setCheckDataThunk } from "./applicant-reducer";

const SET_ACTIVEORDERCARD = "SET_ACTIVEORDERCARD";
const SET_ACTIVESTEPORDERCARD = "SET_ACTIVESTEPORDERCARD";
const SET_IS_SUBMIT_ORDER_FORM = "SET_IS_SUBMIT_ORDER_FORM";
const SET_SUBMIT_ORDER_FORM_MESSAGE = "SET_SUBMIT_ORDER_FORM_MESSAGE";
const SET_IS_ACTIVATED_ORDER_CARD = "SET_IS_ACTIVATED_ORDER_CARD";
const SET_ACTIVATED_ORDER_CARD_MESSAGE = "SET_ACTIVATED_ORDER_CARD_MESSAGE";
const SET_CARD_TYPE_DATA = "SET_CARD_TYPE_DATA";
const SET_BRANCHS_DATA = "SET_BRANCHS_DATA";
const SET_ERROR = "SET_ERROR";
const SET_APPLICANT_DATA = "SET_APPLICANT_DATA";
const SET_PHONE_VERIFY_CODE = "SET_PHONE_VERIFY_CODE";
const SET_OFFER_ID = "SET_OFFER_ID";
const SET_PAYMENT_ORDER_ID = "SET_PAYMENT_ORDER_ID";
const SET_IS_PAYED_FOR_APPLICANT = "SET_IS_PAYED_FOR_APPLICANT";
const SET_ACTIVE_BRANCH = "SET_ACTIVE_BRANCH";
const SET_DEFAULT_VALUES = "SET_DEFAULT_VALUES";
const SET_PAYMENT_TYPES = "SET_PAYMENT_TYPES";

const initialState = {
  phoneVerifyCode: "",
  errorMessage: "",

  //////////////////////
  activeOrderCard: setActiveOrderCard_local(),
  activeBranch: setActiveBranch_local(),
  activeStepOrderCard: 1,
  //////////////////////post/code/form
  isSubmitOrderForm: null,
  submitOrderFormMessage: "",
  isActivatedOrderCard: null,
  activatedOrderCardMessage: "",
  ////////////////////////////////////
  applicantData: {},
  offerId: "",
  //////////////////////////////////
  cardСriteria: [
    {
      title: {
        tm: "Nagt almak boýunça pul tutumy",
        ru: "Комиисия при снятии",
        en: "Nagt almak boýunça pul tutumy",
      },
      value: "cash_percentage",
      currency: "%",
      default: "0.86",
    },
    {
      title: { ru: "Кэшбек", tm: "Keşbek", en: "cashBack" },
      value: "cash_back",
      currency: "%",
      default: "3",
    },
    {
      title: { ru: "Обслуживание", tm: "Hyzmat", en: "Service" },
      value: "cash_amount",
      currency: "TMT",
      default: "23",
    },
    {
      title: {
        ru: "Годовая расчетная процентная ставка",
        tm: "Ýyllyk hasaplama göterim derejesi ",
        en: "Annual calculated interest rate",
      },
      value: "accrued_percentage",
      currency: "%",
      default: "2",
    },
  ],
  /////////////////end of card criteria

  cardCreateType: [
    {
      id: 1,
      title: { ru: "новая карта", en: "new card", tm: "täze kart" },
      value: "new_card",
      disabled: true,
      pin: true,
    },
    {
      id: 2,
      title: {
        ru: "перевыпуск потерянной карты",
        tm: "ýitirlen karty täzeden çap etmek",
        en: "reissue of a lost card",
      },
      value: "lost_card",
      disabled: true,
      pin: true,
    },
    {
      id: 3,
      title: {
        ru: "перевыпуск поломанной карты",
        tm: "zaýalanan karty täzeden çap etmek",
        en: "broken card reissue",
      },
      value: "broken_card",
      disabled: true,
      pin: true,
    },
    {
      id: 4,
      title: {
        ru: "перевыпуск ПИН-конверта к существующей карте",
        tm: "PIN kodyny dikeltmek",
        en: "reissue of a PIN envelope to an existing card",
      },
      value: "pin_card",
      disabled: true,
      pin: true,
    },
  ],
  //////////////////////////////////end of cardcreateType///////////////////
  branchsData: [
    {
      id: 1,
      code: " ",
      name_tm: " ",
      name_ru: " ",
      name_en: " ",
      map_lat: 37.9411075,
      map_lng: 58.3776095,
      working_time: [{ day: "1", end_time: "18:00", start_time: "09:00" }],
    },
  ],

  //////////////////////end of branch///////////////////////
  regionsData: [
    {
      id: 0,
      name: {
        ru: "Ашхабад",
        tm: "Aşgabat",
        en: "Ashgabat",
      },
      reg: "AG",
      search: "Aşgabat,Ашхабад,Ashgabat",
    },
    {
      id: 1,
      name: {
        ru: "Бюзмейн",
        tm: "Büzmeyin",
        en: "Buzmein",
      },
      reg: "AG",
      search: "Büzmeyin,Бюзмейн,Buzmein",
    },
    {
      id: 2,
      name: {
        ru: "Анев",
        tm: "Änew",
        en: "Anev",
      },
      reg: "AH",
      search: "Änew,Анев,Anev",
    },
    {
      id: 3,
      name: {
        ru: "Гёкдепе",
        tm: "Gökdepe",
        en: "Gokdepe",
      },
      reg: "AH",
      search: "Gökdepe,Гёкдепе,Gokdepe",
    },
    {
      id: 4,
      name: {
        ru: "Балканабат",
        tm: "Balkanabat",
        en: "Balkanabat",
      },
      reg: "BN",
      search: "Balkanabat,Балканабат,Balkanabat",
    },
    {
      id: 5,
      name: {
        ru: "Туркменбаши",
        tm: "Türkmenbaşy",
        en: "Turkmenbasy",
      },
      reg: "BN",
      search: "Türkmenbaşy,Туркменбаши,Turkmenbasy",
    },
    {
      id: 6,
      name: {
        ru: "Берекет",
        tm: "Bereket",
        en: "Bereket",
      },
      reg: "BN",
      search: "Bereket,Берекет,Bereket",
    },
    {
      id: 7,
      name: {
        ru: "Гумдаг",
        tm: "Gumdag",
        en: "Gumdag",
      },
      reg: "BN",
      search: "Gumdag,Гумдаг,Gumdag",
    },
    {
      id: 8,
      name: {
        ru: "Хазар",
        tm: "Hazar",
        en: "Hazar",
      },
      reg: "BN",
      search: "Hazar,Хазар,Hazar",
    },
    {
      id: 9,
      name: {
        ru: "Сердар",
        tm: "Serdar",
        en: "Serdar",
      },
      reg: "BN",
      search: "Serdar,Сердар,Serdar",
    },
    {
      id: 10,
      name: {
        ru: "Мары",
        tm: "Mary",
        en: "Mary",
      },
      reg: "MR",
      search: "Mary,Мары,Mary",
    },
    {
      id: 11,
      name: {
        ru: "Байрамали",
        tm: "Baýramaly",
        en: "Bayramaly",
      },
      reg: "MR",
      search: "Baýramaly,Байрамали,Bayramaly",
    },
    {
      id: 12,
      name: {
        ru: "Мургаб",
        tm: "Murgap",
        en: "Murgap",
      },
      reg: "MR",
      search: "Murgap,Мургаб,Murgap",
    },
    {
      id: 13,
      name: {
        ru: "Туркменабад",
        tm: "Türkmenabat",
        en: "Turkmenabat",
      },
      reg: "LB",
      search: "Türkmenabat,Туркменабад,Turkmenabat",
    },
    {
      id: 14,
      name: {
        ru: "Фараб",
        tm: "Farap",
        en: "Farap",
      },
      reg: "LB",
      search: "Farap,Фараб,Farap",
    },
    {
      id: 15,
      name: {
        ru: "Чарджоу",
        tm: "Çärjew",
        en: "Charjev",
      },
      reg: "LB",
      search: "Çärjew,Чарджоу,Charjev",
    },
    {
      id: 16,
      name: {
        ru: "Саят",
        tm: "Saýat",
        en: "Sayat",
      },
      reg: "LB",
      search: "Saýat,Саят,Sayat",
    },
    {
      id: 17,
      name: {
        ru: "Халач",
        tm: "Halaç",
        en: "Halach",
      },
      reg: "LB",
      search: "Halaç,Халач,Halach",
    },
    {
      id: 18,
      name: {
        ru: "Керки",
        tm: "Kerki",
        en: "Kerki",
      },
      reg: "LB",
      search: "Kerki,Керки,Kerki",
    },
    {
      id: 19,
      name: {
        ru: "Чарджоу",
        tm: "Çärjew",
        en: "Charjev",
      },
      reg: "LB",
      search: "Çärjew,Чарджоу,Charjev",
    },
    {
      id: 20,
      name: {
        ru: "Магданли",
        tm: "Magdanly",
        en: "Magdanly",
      },
      reg: "LB",
      search: "Magdanly,Магданли,Magdanly",
    },
    {
      id: 21,
      name: {
        ru: "Сейди",
        tm: "Seýdi",
        en: "Seydi",
      },
      reg: "LB",
      search: "Seýdi,Сейди,Seydi",
    },
    {
      id: 22,
      name: {
        ru: "Дашогуз",
        tm: "Daşoguz",
        en: "Dashoguz",
      },
      reg: "DZ",
      search: "Daşoguz,Дашогуз,Dashoguz",
    },
    {
      id: 23,
      name: {
        ru: "Конеургенч",
        tm: "Könürgenç",
        en: "Koneurgench",
      },
      reg: "DZ",
      search: "Könürgenç,Конеургенч,Koneurgench",
    },
  ],

  ///////////////////////////end of regions//////////////
  passportSeriesData: {
    first: ["I", "II", "III", "IV", "V"],
    second: ["AŞ", "AH", "MR", "LB", "DZ", "BN"],
  },
  stepperTitles: [
    "sargyt edijinin maglumatlary_1",
    "sargyt edijinin pasport maglumatlary_2",
    "yuzlenmanin gornusinin saylamak_3",
  ],

  cardTypeData: [
    // {
    //   id: 1,
    //   card_category_id: 1,
    //   name_tm: " ",
    //   name_ru: " ",
    //   name_en: " ",
    //   description_tm: " ",
    //   description_ru: " ",
    //   description_en: " ",
    //   icon: null,
    //   image:'',
    //   active: 1,
    //   card_category: {
    //     id: 1,
    //     payment_amount_id: 1,
    //     name_tm: " ",
    //     name_ru: " ",
    //     name_en: " ",
    //     description_tm: " ",
    //     description_ru: " ",
    //     description_en: " ",
    //     icon: null,
    //     payment_amount: {
    //       id: 1,
    //       amount: 2300,
    //       name_tm: " ",
    //       name_ru: " ",
    //       name_en: " ",
    //       slug: " ",
    //     },
    //   },
    // },
  ],
  payment_order_id: "",
  isPayedForApplicant: null,
  defaultValues: {
    name: "",
    surname: "",
    patronymic: "",
    phone: "",
    email: "",
    birth_date: null,
    given_date: null,
    passport_serie: "I-AŞ",
    passport_number: null,
    given_place: "",
    // living_place:''
  },
  paymentTypes: [],
};

export const setPaymentTypes = (paymentTypes) => ({
  type: SET_PAYMENT_TYPES,
  paymentTypes,
});

export const setDefaultValues = (defaultValues) => ({
  type: SET_DEFAULT_VALUES,
  defaultValues,
});

export const setActiveOrderCard = (activeOrderCard) => ({
  type: SET_ACTIVEORDERCARD,
  activeOrderCard,
});
export const setActiveStepOrderCard = (activeStepOrderCard) => ({
  type: SET_ACTIVESTEPORDERCARD,
  activeStepOrderCard,
});
export const setActiveBranch = (activeBranch) => ({
  type: SET_ACTIVE_BRANCH,
  activeBranch,
});

const setBranchsData = (branchs) => ({ type: SET_BRANCHS_DATA, branchs });
const setCardsTypeData = (cardsType) => ({
  type: SET_CARD_TYPE_DATA,
  cardsType,
});
export const setErrorMessage = (errorMessage) => ({
  type: SET_ERROR,
  errorMessage,
});
///////////////////////////////////////////////////////////////////////////////////////////////////////
export const setPaymentOrderId = (payment_order_id) => ({
  type: SET_PAYMENT_ORDER_ID,
  payment_order_id,
});
export const setIsPayedForApplicant = (isPayedForApplicant) => ({
  type: SET_IS_PAYED_FOR_APPLICANT,
  isPayedForApplicant,
});

///////////////orderCard///////////////////////
export const setIsSubmitOrderForm = (isSubmitOrderForm) => ({
  type: SET_IS_SUBMIT_ORDER_FORM,
  isSubmitOrderForm,
});

export const setSubmitOrderFormMessage = (submitOrderFormMessage) => ({
  type: SET_SUBMIT_ORDER_FORM_MESSAGE,
  submitOrderFormMessage,
});

export const setIsActivatedOrderCard = (isActivatedOrderCard) => ({
  type: SET_IS_ACTIVATED_ORDER_CARD,
  isActivatedOrderCard,
});

export const setActivedOrderCardMessage = (activatedOrderCardMessage) => ({
  type: SET_ACTIVATED_ORDER_CARD_MESSAGE,
  activatedOrderCardMessage,
});
////////////////////////////////////////////////////////////////////
export const setApplicantData = (applicantData) => ({
  type: SET_APPLICANT_DATA,
  applicantData,
});
export const setVerifyCode = (verifyCode) => ({
  type: SET_PHONE_VERIFY_CODE,
  verifyCode,
});

export const setOfferId = (offerId) => ({
  type: SET_OFFER_ID,
  offerId,
});
//////////////////////////////////////////////////////////////////

export default function cardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PAYMENT_TYPES:
      return {
        ...state,
        paymentTypes: action.paymentTypes,
      };
    case SET_DEFAULT_VALUES:
      return {
        ...state,
        defaultValues: action.defaultValues,
      };
    case SET_ACTIVE_BRANCH:
      return {
        ...state,
        activeBranch: action.activeBranch,
      };
    case SET_IS_PAYED_FOR_APPLICANT:
      return {
        ...state,
        isPayedForApplicant: action.isPayedForApplicant,
      };
    case SET_PAYMENT_ORDER_ID:
      return {
        ...state,
        payment_order_id: action.payment_order_id,
      };
    case SET_CARD_TYPE_DATA:
      return {
        ...state,
        cardTypeData: action.cardsType,
      };
    case SET_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case SET_BRANCHS_DATA:
      return {
        ...state,
        branchsData: action.branchs,
      };
    case SET_ACTIVEORDERCARD:
      return {
        ...state,
        activeOrderCard: action.activeOrderCard,
      };
    case SET_ACTIVESTEPORDERCARD:
      return {
        ...state,
        activeStepOrderCard: action.activeStepOrderCard,
      };
    case SET_IS_SUBMIT_ORDER_FORM:
      return {
        ...state,
        isSubmitOrderForm: action.isSubmitOrderForm,
      };
    case SET_SUBMIT_ORDER_FORM_MESSAGE:
      return {
        ...state,
        submitOrderFormMessage: action.submitOrderFormMessage,
      };
    case SET_IS_ACTIVATED_ORDER_CARD:
      return {
        ...state,
        isActivatedOrderCard: action.isActivatedOrderCard,
      };
    case SET_ACTIVATED_ORDER_CARD_MESSAGE:
      return {
        ...state,
        activatedOrderCardMessage: action.activatedOrderCardMessage,
      };
    case SET_APPLICANT_DATA:
      return {
        ...state,
        applicantData: action.applicantData,
      };
    case SET_PHONE_VERIFY_CODE:
      return {
        ...state,
        phoneVerifyCode: action.verifyCode,
      };
    case SET_OFFER_ID:
      return {
        ...state,
        offerId: action.offerId,
      };
    default:
      return state;
  }
}

export const setCardsDataThunk = (data) => {
  return async (dispatch) => {
    try {
      dispatch(setIsFetching(true));
      const response = await CardAPI.getCardsData();
      if (response.status === 200 && response.data.status === 0) {
        // const branchs = await response.data.data.branchs.map((i) => {
        //   return {
        //     ...i,
        //     reg: determineRegByCode(i.code),
        //   };
        // });
        // debugger
        dispatch(setBranchsData(response.data.data.states));
        dispatch(setCardsTypeData(response.data.data.card_types));
        dispatch(setPaymentTypes(response.data.data.payments));
      }
      dispatch(setIsFetching(false));
    } catch (e) {
      if (e.response && e.response.status === 500) {
      }
      dispatch(setIsFetching(false));
    }
  };
};

export const submitOrderFormThunk = (data) => {
  return async (dispatch) => {
    dispatch(setIsSubmitOrderForm(null));
    dispatch(setSubmitOrderFormMessage(""));
    try {
      dispatch(setIsFetching(true));
      const response = await CardAPI.postOrderCardForm(data);
      if (response.status === 200 && response.data.status === 0) {
        dispatch(setIsSubmitOrderForm(true));
        dispatch(
          setSubmitOrderFormMessage(returnTranslation("success-submitted"))
        );
        dispatch(setApplicantData(response.data.data.applicant));
        dispatch(setVerifyCode(response.data.data.code));
      } else if (response.status === 200 && response.data.status === 1) {
        dispatch(setIsSubmitOrderForm(false));
        dispatch(setSubmitOrderFormMessage("Заявка уже существует"));
      }
      dispatch(setIsFetching(false));
    } catch (e) {
      dispatch(setIsSubmitOrderForm(false));
      dispatch(setSubmitOrderFormMessage(returnTranslation("some-errors")));
      dispatch(setIsFetching(false));
    }
  };
};

export const activateOrderCardThunk = (data) => {
  return async (dispatch) => {
    dispatch(setIsActivatedOrderCard(null));
    dispatch(setActivedOrderCardMessage(""));
    dispatch(setOfferId(""));
    try {
      dispatch(setIsFetching(true));
      const response = await CardAPI.postActivateOrderCard(data);
      if (
        (response.status === 200 && response.data.status === 1) ||
        (response.status === 200 && response.data.status === 2)
      ) {
        dispatch(setIsActivatedOrderCard(false));
        dispatch(
          setActivedOrderCardMessage(returnTranslation("invalid-activate-code"))
        );
      } else if (response.status === 200 && response.data.status === 0) {
        dispatch(setOfferId(response.data.data.offer_id));
        dispatch(setIsActivatedOrderCard(true));
        dispatch(setVerifyCode(""));
        dispatch(
          setActivedOrderCardMessage(
            returnTranslation("success-order-activated")
          )
        );
      }
      dispatch(setIsFetching(false));
    } catch (e) {
      if (e.response && e.response.status === 400) {
        dispatch(setIsActivatedOrderCard(false));
        dispatch(
          setActivedOrderCardMessage(returnTranslation("invalid-activate-code"))
        );
      } else {
        dispatch(setIsActivatedOrderCard(false));
        dispatch(setActivedOrderCardMessage(returnTranslation("some-errors")));
      }
      dispatch(setIsFetching(false));
    }
  };
};

export const setActivateOrderCodeAgainThunk = (data) => {
  return async (dispatch) => {
    dispatch(setActivedOrderCardMessage(""));
    try {
      dispatch(setIsFetching(true));
      const response = await CardAPI.postActivateCodeAgain(data);
      if (response.status === 200 && response.data.status === 0) {
        dispatch(setVerifyCode(response.data.code));
      }
      dispatch(setIsFetching(false));
    } catch (e) {
      dispatch(setIsFetching(false));
      if (e.response && e.response.status === 400) {
        dispatch(
          setActivedOrderCardMessage(returnTranslation("invalid-activate-code"))
        );
      } else {
        dispatch(setActivedOrderCardMessage(returnTranslation("some-errors")));
      }
    }
  };
};

export const checkPayment = (orderId) => {
  return async (dispatch) => {
    dispatch(setIsFetching(true));
    dispatch(setIsPayedForApplicant(null));

    try {
      const response = await CardAPI.getCheckPayment(orderId);
      // debugger
      if (response.data.status === 11) {
        dispatch(setIsPayedForApplicant(3));
      } else if (
        response.status === 200 &&
        (response.data.status === 0 || response.data.status === 7)
      ) {
        dispatch(setIsPayedForApplicant(1));

        // dispatch(
        //   setCheckDataThunk({
        //     order_id: JSON.parse(
        //       localStorage.getItem(config.localStorageNames.applicantInfo)
        //     ).order_id,
        //     tiket: JSON.parse(
        //       localStorage.getItem(config.localStorageNames.applicantInfo)
        //     ).tiket,
        //   })
        // );
        localStorage.removeItem(config.localStorageNames.applicantInfo);
      } else {
        dispatch(setIsPayedForApplicant(0));
      }
      dispatch(setIsFetching(false));
    } catch (e) {}
  };
};
