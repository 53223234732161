import i18n from "../i18n";
import { config } from "./config";

export const determineRegByCode = (code) => {
  switch (code[0]) {
    case "1":
      return "Aşgabat";
    case "2":
      return "Ahal";
    case "6":
      return "Balkan";
    case "7":
      return "Mary";
    case "8":
      return "Lebap";
    case "9":
      return "Daşoguz";
    default:
      return "Aşgabat";
  }
};

export const returnTranslation = (word) => {
  return i18n.store.data[i18n.language].translation[word];
};

export const returnOrderedText = (text, variable, tmIndex = 0, ruIndex = 1) => {
  var array = text.split(" ");

  if (i18n.language === "tm") {
    array.splice(tmIndex, 0, variable.tm);
  } else {
    var v = variable[i18n.language] ? variable[i18n.language] : variable.tm;
    array.splice(ruIndex, 0, v);
  }

  return array.join(" ");
};

export const siteMessages = {
  validation: {
    inputCorrectly: function (input) {
      var res = returnOrderedText(returnTranslation("input-correctly"), input);
      return res;
    },
    maxLength: function (max) {
      return `Максимум ${max} символов`;
    },
    minLength: function (min) {
      return `Мимнумум ${min} символов`;
    },
  },
};

export const setActiveOrderCard_local = () => {
  if (localStorage.getItem(config.localStorageNames.activeCard) !== null) {
    return localStorage.getItem(config.localStorageNames.activeCard);
  } else {
    localStorage.setItem(config.localStorageNames.activeCard, 0);
    return 0;
  }
};
export const setActiveBranch_local = () => {
  if (localStorage.getItem(config.localStorageNames.activeBranch) !== null) {
    return localStorage.getItem(config.localStorageNames.activeBranch);
  } else {
    localStorage.setItem(config.localStorageNames.activeBranch, 0);
    return 0;
  }
};

export const clearNumber = (phone) => {
  var n = phone;
  n = n.replaceAll("-", "");
  n = n.replaceAll(" ", "");
  n = n.replaceAll("(", "");
  n = n.replaceAll(")", "");
  n = n.replace("+993", "");
  return n;
};

export const transValidNumber = (phone) => {
  if (phone?.length === 8) {
    var n = phone;
    var n1 = n.slice(0, 2);
    var n2 = n.slice(2, 4);
    var n3 = n.slice(4, 6);
    var n4 = n.slice(6, 8);
    return n1[1] + ") " + n2 + "-" + n3 + "-" + n4;
  } else return "";
};

export const returnActiveData = (activeIndex, indexName, data) => {
  var instance = {};
  data.map((item) => {
    if (item[indexName] === activeIndex) {
      instance = item;
    }
  });
  return instance;
};

///////////////////////////working with dates////////////
export const reverseDate = (date) => {
  if (typeof date === "string") {
    var newData = date.split("T")[0];
    var d = newData.split("-").reverse().join("-");
    return d;
  }
};

export const disabledFutureDateChecker = (date) => {
  if (typeof date === "object") {
    var now = new Date();
    return date < now;
  } else return false;
};

export const DatePastChecker = (date, index) => {
  if (typeof date === "object") {
    var now = new Date();
    return now.getFullYear() - date.getFullYear() < index;
  } else return false;
};

export const differenceBetweenAges = (age1, age2, equal) => {
  // debugger
  if (typeof age1 === "object" && typeof age2 === "object") {
    return (age2 - age1) / 1000 / 60 / 60 / 24 / 365 >= equal;
  }
  return false;
};
