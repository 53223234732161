import React from 'react'
import { connect } from 'react-redux'
import { setOpenCheckOrder, setOpenSidebar } from '../../redux/data-reducer'
import Header from './Header'



class HeaderContainer extends  React.Component {
    render(){
        const handleOpenSidebar = ()=>{
            this.props.setOpenSidebar(true)
        }
        const handleOpenCheckOrder =()=>{
            this.props.setOpenCheckOrder(true)
        }
        return (
            <Header {...this.props} handleOpenCheckOrder={handleOpenCheckOrder} handleOpenSidebar ={handleOpenSidebar}/>
        )
    }
}




const mapStateToProps = (state) =>({
    navRoutes: state.data.navRoutes,
    defaultValues: state.cardPage.defaultValues
})


export default connect(mapStateToProps,{
    setOpenSidebar,setOpenCheckOrder
})(HeaderContainer)