import { Help } from "@material-ui/icons";


const SET_IS_FETCHING = "SET_IS_FETCHING";
const SET_IS_CONNECTED = "SET_IS_CONNECTED";
const SET_OPEN_MAIN_CARD_MODAL = "SET_OPEN_MAIN_CARD_MODAL";
const SET_OPEN_SIDEBAR = "SET_OPEN_SIDEBAR";
const SET_OPEN_CHECK_ORDER = "SET_OPEN_CHECK_ORDER";
const SET_IS_SHOWED_SPEED_DIAL = 'SET_IS_SHOWED_SPEED_DIAL'
const SET_DIRECTION_SPEED_DIAL  = 'SET_DIRECTION_SPEED_DIAL'

export const setIsFetching = (isFetChing) => ({
  type: SET_IS_FETCHING,
  isFetChing,
});
export const setIsConnected = (isConnected) => ({
  type: SET_IS_CONNECTED,
  isConnected,
});
///////////////////////////////////////////////////local//////////////////////////////////////
export const setOpenMainCardModal = (openMainCardModal) => ({
  type: SET_OPEN_MAIN_CARD_MODAL,
  openMainCardModal,
});
export const setOpenSidebar = (openSidebar) => ({
  type: SET_OPEN_SIDEBAR,
  openSidebar,
});

export const setOpenCheckOrder = (openCheckOrder) => ({
  type: SET_OPEN_CHECK_ORDER,
  openCheckOrder,
});
////////////////////////////////////////////////////////////////////////////////////////////
export const setIsShowedSpeedDial = (isShowedSpeedDial) =>({
  type: SET_IS_SHOWED_SPEED_DIAL,
  isShowedSpeedDial
})

export const setDirectionSpeedDial = (directionSpeedDial) =>({
  type: SET_DIRECTION_SPEED_DIAL,
  directionSpeedDial
})
///////////////////////////////////////////////////////////////////////////////////////////

const initialState = {
  isFetching: false,
  isConnected: true,
  ///////////////////////////
  openMainCardModal: true,
  openSidebar: false,
  openCheckOrder: false,
  ///////////////////////////////
  isShowedSpeedDial:true,
  directionSpeedDial:'up',
  speedDialItems:[
    {id:0,icon:<Help />, name:'help'},
    {id:0,icon:<Help />, name:'help'},
    {id:0,icon:<Help />, name:'help'},
    {id:0,icon:<Help />, name:'help'},

  ],
  ////////////////////////////////////////////

  languagesData: [
    { value: "tm" }, 
    { value: "ru" }, 
    // { value: "en" }
  ],
  footerPage: {
    address: {
      main: "744000, Aşgabat şäheriniň Görogly köçesi, 79",
    },
    phone: {
      main: ["+993 (12) 44-02-57"],
    },
    email: {
      main: "info@tnbk.tm",
    },
  },
};

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OPEN_CHECK_ORDER:
      return { ...state, openCheckOrder: action.openCheckOrder };
    case SET_OPEN_SIDEBAR:
      return { ...state, openSidebar: action.openSidebar };
    case SET_OPEN_MAIN_CARD_MODAL:
      return {
        ...state,
        openMainCardModal: action.openMainCardModal,
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetChing,
      };
    case SET_IS_CONNECTED:
      return {
        ...state,
        isConnected: action.isConnected,
      };
    default:
      return state;
  }
}



export const BadRequestThunk = (status,message,func) =>{
  return dispatch =>{
    if (status ===400){
      dispatch(func(message))
    }
  }
}