import axios from "axios";
import { config } from "../common/config";

const instanceAxios = axios.create({
  // baseURL: "/api",
  baseURL: config.baseURL + "/api",
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  },
});

export const CardAPI = {
  async getCardsData() {
    return await instanceAxios.get('/')
  },

  async postOrderCardForm(data) {
    return await instanceAxios.post('/applicant', data)
  },

  async postActivateOrderCard(data) {
    return await instanceAxios.post('/applicant/phoneverify', data)
  },

  async postActivateCodeAgain(data) {
    return await instanceAxios.post('/applicant/sendagain', data)
  },

  async postSubmitOrderFormCode(data) {
    return await instanceAxios.post('/submitCode', data)
  },

  async postCheckApplicant(data) {
    return await instanceAxios.post('/applicant/check-applicant', data)

  },

  async postCheckPassport(data) {

    return await instanceAxios.post('/applicant/check-passport', data)
  },

  async postRejectApplicant(data) {
    return await instanceAxios.post('/applicant/reject-applicant', data)
  },
  
  async postModifyApplicant(data) {
    return await instanceAxios.post('/applicant/modify-applicant', data)
  },
  async postTakeReadyCard(data) {
    return await instanceAxios.post('/applicant/taking-card', data)
  },
  /////////////////////////////////payment/////
  async postPayApplicant(data) {
    return await instanceAxios.post('/applicant/pay', data)
  },
  async getCheckPayment(data) {
    // debuggerS
    return await instanceAxios.get('/applicant/check-pay', { params: { orderId: data } })
  }
}

export const siteAPI = {
  async getInputSearch(data) {
    return await instanceAxios.get('/info-input', { params: { type: data.type, q: data.q } })
  }
}


