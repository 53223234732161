const initialState = {
  cardTypeData: [
    {
      id: 3,
      amount: 30000,
      percentage: 5,

      name: {
        tm: "Потребительский кредит",
        ru: "Потребительский кредит",
        en: "Потребительский кредит",
      },

      params: [
        {
          id: 10,
          title: {
            ru: `ежемесячное начисление 5% на остаток`,
            tm: "ежемесячное начисление 5% на остаток",
            en: "ежемесячное начисление 5% на остаток",
          },
        },
        {
          id: 11,
          title: {
            ru: "частичный возврат денег за покупки",
            tm: "частичный возврат денег за покупки",
            en: "частичный возврат денег за покупки",
          },
        },
      ],
      descriptions: {
        ru: {
          full: "банковская карта, предназначенная для выплаты заработной платы и других денежных начислений (премий, командировочных, материальной помощи и т.д.) сотруднику организацией, заключившей договор на обслуживание с банком в рамках зарплатного проекта. Каждому сотруднику выдается именная карта для перечисления на ее счет вознаграждения",
          short:
            "банковская карта, предназначенная для выплаты заработной платы и других денежных начислений",
        },
        tm: {
          full: "банковская карта, предназначенная для выплаты заработной платы и других денежных начислений (премий, командировочных, материальной помощи и т.д.) сотруднику организацией, заключившей договор на обслуживание с банком в рамках зарплатного проекта. Каждому сотруднику выдается именная карта для перечисления на ее счет вознаграждения",
          short:
            "банковская карта, предназначенная для выплаты заработной платы и других денежных начислений",
        },
        en: {
          full: "банковская карта, предназначенная для выплаты заработной платы и других денежных начислений (премий, командировочных, материальной помощи и т.д.) сотруднику организацией, заключившей договор на обслуживание с банком в рамках зарплатного проекта. Каждому сотруднику выдается именная карта для перечисления на ее счет вознаграждения",
          short:
            "банковская карта, предназначенная для выплаты заработной платы и других денежных начислений",
        },
      },
    },
  ],
};

export default function creditReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
