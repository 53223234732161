import React from 'react'
import { connect } from 'react-redux'
import Loader from './Loader'



class LoaderContainer extends React.Component {
    render(){
        if (this.props.isFetching){
            return(
                <Loader {...this.props}/>
            )

        }
        else return <div style={{display:'none'}}></div>
    }
}



const mapStateToProps = (state) =>({
    isFetching: state.data.isFetching
})



export default connect(mapStateToProps,{

})(LoaderContainer)