import {resources} from './common/messages'
import i18n from 'i18next'
import { initReactI18next } from "react-i18next";
import { config } from './common/config';
//


var setLang = ()=>{
  var currentLang = localStorage.getItem(config.localStorageNames.lang) 
  if(currentLang&&(currentLang === 'ru'||currentLang === 'tm')){
    return currentLang
  }
  else{
    localStorage.setItem(config.localStorageNames.lang,'ru')
    return 'ru'

  }
}


i18n
  
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: setLang(),

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      // Turn off the use of React Suspense
      useSuspense: false
    }
  });
// window.siteLang = i18n;


export default i18n;


