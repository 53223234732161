
export const config = {
    baseURL :'https://sargyt.tnbk.tm/api',
    // baseURL: 'http://93.171.223.101:6280',
    reCaptchaKey:'6Lcco_EbAAAAAKNaRxFx-z14nQBdNmP7bZjJVpOy',
    googleMapKey:'AIzaSyCgzwo8Ery6YLHckFIS9Qc79kjdPVUgMlE',
    yandexMapKey:'1810544c-729c-4043-b4ca-d6cd11100aa2',
    localStorageNames:{
        lang:'lang',
        activeCard:'activeCard',
        applicantInfo:'applicantInfo',
        activeBranch:'activeBranch'
    },
    alertMessageDuration:3000

}


export const siteColors = {
    header:{
        top:{
            border:'#20DE01',
            borderGradient: 'linear-gradient(45deg, rgb(32,222,1), rgb(250,224,66)) 1;'
        },
        bgc:{
            primary:'#F5F6F8',
            second:'#F1F5F7'
        }
    },
    body:{
        bgc:{
            primary:'#fff'
        }
    },
    main:{
        primary:'#32CA61',
        // primary:'#20DE01',
        logo:'#32A661'
    },
    footer:{
        bgc:'#F7F7F9',
        bottomBgc:'#172A1E'
    },
    titles:{
        second:'rgba(38,38,38,.55)'
    }
}


export const commonFunc = {
    validation:{
        name: function(text,min = 3,max = 25) {
            // debugger
            if((/^[\D]+$/g.test(text))&&text.length<=max&&text.length>=min){
                return true
            }
            else return false
        },
        email:{
            pattern : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        },
        phoneNumber:{
            // pattern: /^[+][993]{3}[ ][(][6][1-5][)][ ][0-9]{2}[-][0-9]{2}[-][0-9]{2}$/g
            pattern: /^[1-5][)][ ][0-9]{2}[-][0-9]{2}[-][0-9]{2}$/g

        }
    },
    getNormalDate:function(date){
        if (date){
            var a = date.getFullYear()
            var b = date.getMonth()+1
            var c = date.getDate()
            // var d = date.getHours()
            // var e = date.getMinutes()
            // return {
            //     front: c+' - '+b+' - '+a,
            //     back: a +'-'+b+'-'+c,
            //     fullFront: c+' - '+b+' - '+a+" "+d+":"+e
            // }
            return a +'-'+this.zeroFill(b,2)+'-'+this.zeroFill(c,2)
        }
    },
    zeroFill: (number, width) => {
        width -= number.toString().length;
        if (width > 0) {
            return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;

        }
        return number + ""; // always return a string
    },
}


