import React from "react";
import { Menu,MenuItem} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { config, siteColors } from "../../../../common/config";




const LangPanel = (props) => {
    
    const {t,i18n} = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const changeLang = (lan) =>{
        i18n.changeLanguage(lan)
        localStorage.setItem(config.localStorageNames.lang,lan)
        handleClose()
    }
  return (
    <div>
        <button  style={{color:siteColors.main.primary,fontWeight:'400',cursor:'pointer' ,outline:'none', background:'transparent',textTransform:'uppercase', border:'none', fontSize:'18px'}}  
        onClick={handleClick}>{i18n.language}</button>
      
      <Menu
        id="language-manu-panel"
        aria-labelledby="language-menu-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // anchorOrigin={{
        //   vertical: "top",
        //   horizontal: "left",
        // }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {props.langData.map((i,index)=>{
            return(
                <MenuItem onClick={()=>{changeLang(i.value)}} disabled={i.value ===i18n.language} key={i.value+index} sx={{textTransform:'uppercase'}}>{i.value}</MenuItem>
            )
        })}
      </Menu>
    </div>
  );
};


export default LangPanel
