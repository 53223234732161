import { Button, Container,  createTheme, Grid } from '@material-ui/core'

import { Menu } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { siteColors } from '../../common/config'
import logo from './../../assests/logo_large.png'
import HeadPanel from './HeadPanel/HeadPanel'

const theme = createTheme()
const useStyles  = makeStyles(()=>({
   wrapper:{
       borderTopWidth:'6px',
       borderTopStyle:'solid',
       borderImage: siteColors.header.top.borderGradient,
       paddingTop:"1rem",
       paddingBottom:'1rem',
       borderBottom:'1px solid #ddd'
   },
//    dFlex:{
//        display:'flex',
//        alignItems:'center',
//        [theme.breakpoints.down('sm')]:{
//            marginBottom:'1rem'
//        }
       
//    },
   logoTitle:{
        // fontWeight:'700',
        marginLeft:'1rem',
        textTransform:'uppercase',
        color: siteColors.main.logo,
        [theme.breakpoints.down('sm')]:{
            fontSize:'1rem',
            textAlign:'center',
            
        }
   },
   menu:{
      display:'none',
      [theme.breakpoints.down('sm')]:{
          display:'inline-block',
          float:'left',
          paddingTop:'20px'
      },
      '&:hover':{
          cursor:'pointer'
      }
   },
   box:{
    [theme.breakpoints.down('sm')]:{
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between'
    }
   }
}))

const Header = (props) =>{
//  debugger
    const classes = useStyles()
    const {t} = useTranslation()
    return(
        <div>
        <div className={classes.wrapper}>
          <Container>
                <Grid container alignItems={'center'}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <a href="https://www.tnbk.tm/" target="blank">
                            <div className={classes.dFlex}>
                                <img src={logo} width={'400'} height='auto' alt="logo"/>
                                {/* <span className={classes.logoTitle +" " +'h4'}><strong>"Türkmenistan"</strong> döwlet täjirçilik banky</span> */}
                            </div>
                        </a>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} sx={{textAlign:'right'}} className={classes.box}>
                        <span className={classes.menu} onClick={()=>{props.handleOpenSidebar()}}>
                        <Menu sx={{fontSize:'2rem'}}/>
                        </span>
                        <Button variant='outlined' size='large' onClick={()=>{props.handleOpenCheckOrder()}}>{t('check-order')}</Button>
                    </Grid>

                </Grid>
          </Container>
        </div>
          <HeadPanel navRoutes={props.navRoutes} defaultValues={props.defaultValues}/>
        </div>
    )
}



export default Header