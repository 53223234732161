import React from 'react'
import { connect } from 'react-redux'
import LangPanel from './LangPanel'



class LangPanelContainer extends React.Component {
    render(){
        return(
            <LangPanel {...this.props}/>
        )
    }
}


const mapStateToProps= (state) =>({
    langData: state.data.languagesData
})


export default connect(mapStateToProps,{})(LangPanelContainer)