import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import "./App.css";
import FooterContainer from "./Components/Footer/FooterContainer";
import HeaderContainer from "./Components/Header/HeaderContainer";
import LoaderContainer from "./Components/common/Loader/LoaderContainer";
import { publicRoutes, RoutesNames } from "./common/routes";
import { useSelector } from "react-redux";
// import SpeedDialComponent from './Components/modal/SpeedDial/SpeedDial'
import Loader from "./Components/common/Loader/Loader";
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { tr ,ru, enUS} from "date-fns/locale";
import i18n from "./i18n";
import { GlobalDebug } from "./remove-console";




const SidebarContainer = React.lazy(()=>import('./Components/SidebarPage/SidebarContainer'))
const NoNet = React.lazy(()=>import('./Components/common/NoNet/NoNet'))
const CheckOrderContainer = React.lazy(()=>import('./Components/modal/checkOrder/CheckOrderContainer'))


const App  = (props)=> {
  const history = useHistory()
  
  const hasApplicant = useSelector(state=>state.applicantPage.hasApplicant);
 
  // debugger
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
     process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);


  if (hasApplicant){
    history.push(RoutesNames.APPLICANT)
  }  
  const ls = {
    ru: ru,
    tm: tr,
    en: enUS

  }
    
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={ls[i18n.language]}>
      <div className="App">
        <React.Suspense fallback={<Loader />}>
        <LoaderContainer />
        <HeaderContainer />
        <CheckOrderContainer />
        {/* <SpeedDialComponent actions={props.speedDialItems}/> */}
        <NoNet />
        <SidebarContainer />
          <Switch>
            {publicRoutes.map((routes) => {
              return (
                <Route
                path={routes.path}
                exact={routes.exact}
                component={routes.component}
                key={routes.path}
                />
                );
              })}
            
            <Redirect to={RoutesNames.CARDS} />
          </Switch>
              {/* <LoaderMini /> */}
        <FooterContainer />
        </React.Suspense>
      </div>
      </LocalizationProvider>
    );
  }







export  default App


