import React from 'react'
import { connect } from 'react-redux'
import Footer from './Footer'




class FooterContainer extends React.Component {
    render(){
        return(
            <Footer {...this.props}/>
        )
    }
}


const mapStateToProps = (state) =>({
    data:state.data.footerPage
})

export default connect(mapStateToProps,{
    
})(FooterContainer)