import { Container, Grid, Typography } from "@material-ui/core";
import { Email, LocationOn, Phone } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { siteColors } from "../../common/config";

import logo from './../../assests/logo.png'
import FooterBottom from "./FooterBottom/FooterBottom";


// const theme = createTheme();
const useStyles = makeStyles(() => ({
  wrapper: {
   
    paddingTop:'2rem',
    borderTop: "1px solid #ccc",
    
    backgroundColor:siteColors.footer.bgc,

  },
  logoWrapper:{
    display:'flex',
    alignItems:'center',
    // padding:'1rem'
  },
  logoTitles:{
    marginLeft:'1rem'
  },
  logoTitle:{
    color:siteColors.main.logo,
    fontWeight:'500'
  },
  borderBottom:{
    borderBottom:'2px solid #ccc'
  },

  itemMainTitle:{
    textTransform:'uppercase',
    display:'flex',
    alignItems:'center',
    
  },
  itemMainTitleIcon:{
    marginRight:'0.5rem'
  },
  itemMainContent:{
    padding:'0',
    color:'#666'
    
  }

}));

const Footer = (props) => {
  const classes = useStyles();
  const {t} = useTranslation()
  return (
    <div className={classes.wrapper}>

   
      <Container>
          <Grid container spacing={2}>
                <Grid container item lg={8} md={8} sm={8} xs={12} flexDirection='column' spacing={4}>
                    <Grid item>
                      <div>
                        <Typography component='h5'className={classes.itemMainTitle} sx={{fontWeight:'bold'}} mb={'0.7rem'}><LocationOn className={classes.itemMainTitleIcon}/> {t('address')}</Typography>
                          <div className={classes.itemMainContent}>
                            {props.data.address.main}
                          </div>
                      </div>
                    </Grid>
                    <Grid item>
                      <div>
                      <Typography component='h5'className={classes.itemMainTitle} sx={{fontWeight:'bold'}}  mb={'0.7rem'}><Phone className={classes.itemMainTitleIcon}/> {t('phone')}</Typography>
                      <ul style={{listStyle:'none' ,padding:'0', margin:'0'}}>
                          {props.data.phone.main.map((p,index)=>{
                            return <li className={classes.itemMainContent} key={`phone${index}`}>
                                {p}
                            </li>
                          })}
                      </ul>
                      </div>
                    </Grid>
                    <Grid item>
                      <div>
                      <Typography component='h5'className={classes.itemMainTitle} sx={{fontWeight:'bold'}} mb={'0.7rem'}><Email className={classes.itemMainTitleIcon}/> {t('email')}</Typography>
                      <div className={classes.itemMainContent}>
                        {props.data.email.main}
                      </div>
                      </div>
                    </Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                      <div className={classes.logoWrapper}>
                        <img src={logo} alt="logo" width='120' height='auto'/>
                        <div className={classes.logoTitles}>
                          <span className={classes.logoTitle+" "+'h3'}>Türkmenistan DTB</span>
                          <div>Hemişe Siziň hyzmatyňyzda</div>
                        </div>
                      </div>
                      
                </Grid>

          </Grid>
          <br/>
      </Container>
          <FooterBottom />
    </div>
  );
};

export default Footer;
