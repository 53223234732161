
import {   Chip, Container, createTheme, Divider, Grid, Tooltip,} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { makeStyles} from "@material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
// import { Link, NavLink } from "react-router-dom";
import { siteColors } from "../../../common/config";
import { navRoutes } from "../../../common/routes";
import LangPanelContainer from "./LangPanel/LangPanelContainer";
const theme = createTheme();

const useStyles = makeStyles(() => ({
  wrapper: {
    borderBottom: "1px solid #ddd",
    padding: "1rem",
    [theme.breakpoints.down('sm')]:{
        display:'none'
    }
  },
  navItem: {
    marginLeft: "2rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
  },
  navItemIcon: {
    marginRight: "0.4rem",
  },
  navItemTitle: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  link:{
      color:'#666',
      '&:hover':{
        color:siteColors.main.logo
      }
  },
  activeLink:{
    color:siteColors.main.logo
  }
}));



const HeadPanel = (props) => {
  const {t} = useTranslation()
  
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={2}  sx={{xs:'none'}}>
              <LangPanelContainer />
          </Grid>
          <Grid
            item
            lg={8}
            md={8}
            sm={10}
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {navRoutes.map((route,index)=>{
              return(
                <route.component to={route.path} onClick={route.action} className={classes.link} key={route.path}  activeClassName={classes.activeLink}>
            <div className={classes.navItem} style={index===0?{ marginLeft: "0" }:{}}>
                <route.icon className={classes.navItemIcon} />
                <span className={classes.navItemTitle}>{t(route.title)}</span>
            </div>
              </route.component>
              )
            })}
            {/* <Divider orientation='vertical' sx={{margin:'0 1rem'}} /> */}
            {/* <div className={classes.navItem} style={{marginLeft:'0',color:'#666'}}>
               
                <AccountCircle className={classes.navItemIcon} /> 
               
                {(props.defaultValues?.name || props.defaultValues?.phone)?<span className={classes.navItemTitle}>{props.defaultValues?.name}</span>:
                // <span className={classes.navItemTitle}>{t('guest')}</span>
                <span></span>
                }

           
            </div> */}
           
              
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HeadPanel;
