import { Backdrop, CircularProgress } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import s from './Loader.module.css'
const Loader = (props) => {
  const {t} = useTranslation()
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 999999 }}
        open={true}
      >
        <div className={s.content + ' t-center'}>
          <CircularProgress color="inherit" />
          <span className={s.text + ' h3'}>
            {t('loading-please-wait')}
          </span>
        </div>
      </Backdrop>
    </div>
  );
};

export default Loader;
